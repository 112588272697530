define("ember-cli-materialize/components/selectable-item", ["exports", "ember-composability/mixins/child-component-support", "ember-cli-materialize/components/selectable-item-group", "ember-new-computed"], function (_exports, _childComponentSupport, _selectableItemGroup, _emberNewComputed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { var _i = arr && (typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]); if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var _default = Ember.Component.extend(_childComponentSupport.default, {
    // eslint-disable-next-line
    _parentComponentTypes: [_selectableItemGroup.default],
    checked: null,
    disabled: false,
    classNames: ['materialize-selectable-item'],
    _checked: (0, _emberNewComputed.default)('checked', 'group.selection', 'group.selection.[]', {
      get: function get() {
        var group = this.get('group');

        if (!group) {
          return this.get('checked');
        } else {
          return group.isValueSelected(this.get('value'));
        }
      },
      set: function set(key, val) {
        var group = this.get('group');

        if (!group) {
          this.set('checked', val);
        } else {
          group.setValueSelection(this.get('value'), val);
        }

        this.sendAction('action', {
          checked: !!val
        });
        return !!val;
      }
    }),
    isSelected: Ember.computed.alias('_checked'),
    _setupLabel: function _setupLabel() {
      var _this$$$toArray = this.$('.materialize-selectable-item-input, .materialize-selectable-item-input-container input').toArray(),
          _this$$$toArray2 = _slicedToArray(_this$$$toArray, 1),
          $input = _this$$$toArray2[0];

      var inputId = $input ? $input.id : null;
      this.$('.materialize-selectable-item-label').attr('for', inputId);
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this._setupLabel();
    },
    group: Ember.computed(function () {
      return this.nearestWithProperty('__materializeSelectableItemGroup');
    })
  });

  _exports.default = _default;
});