define("ember-cli-materialize/components/md-tabs", ["exports", "ember-composability/mixins/parent-component-support", "ember-cli-materialize/templates/components/md-tabs"], function (_exports, _parentComponentSupport, _mdTabs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { var _i = arr && (typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]); if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var _default = Ember.Component.extend(_parentComponentSupport.default, {
    layout: _mdTabs.default,
    classNames: ['materialize-tabs', 'row'],
    composableChildrenDebounceTime: 1,
    content: null,
    numTabs: Ember.computed.alias('composableChildren.length'),
    optionValuePath: 'id',
    optionLabelPath: 'title',
    colWidth: 2,
    selected: null,
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this._updateIndicatorPosition(false);
    },
    _indicatorUpdater: Ember.observer('selected', 'content.[]', 'composableChildren.[]', function () {
      Ember.run.debounce(this, this._updateIndicatorPosition, 100);
    }),
    tabComponents: function tabComponents() {
      return Ember.A(this.get('composableChildren')) || Ember.A();
    },
    _updateIndicatorPosition: function _updateIndicatorPosition() {
      var _this = this;

      var animate = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;

      if (!this.element) {
        return;
      }

      var _filter = (this.get('composableChildren') || []).filter(function (item) {
        return Ember.get(item, 'value') === _this.get('selected');
      }),
          _filter2 = _slicedToArray(_filter, 1),
          tabComponent = _filter2[0];

      var tabSetRect = this.element.getBoundingClientRect();

      if (tabComponent) {
        var tabRect = tabComponent.element.getBoundingClientRect();
        var cssParams = {
          left: tabRect.left - tabSetRect.left,
          right: tabSetRect.right - tabRect.right
        };

        if (!animate) {
          this.$('.indicator').css(cssParams);
        } else {
          this.$('.indicator1').velocity(cssParams, {
            duration: 150
          });
          this.$('.indicator2').velocity(cssParams, {
            duration: 150,
            delay: 40
          });
        }
      }
    },
    _content: Ember.computed('content.[]', 'optionLabelPath', 'optionValuePath', function () {
      var labelPath = this.get('optionLabelPath');
      var valuePath = this.get('optionValuePath');
      return new Ember.A((this.get('content') || []).map(function (contentItem) {
        return {
          id: contentItem[valuePath],
          title: contentItem[labelPath]
        };
      }));
    })
  });

  _exports.default = _default;
});